import { FleetOwner } from '@/domain/fleet/types'
import Link from 'next/link'
import Button from '../button'

const FleetButton = ({ fleetOwner }: { fleetOwner: FleetOwner }) => {
  return (
    <>
      <Link href={`/fleetOwner/${fleetOwner.fleetOwnerId}`}>
        <Button style={{ display: 'block', margin: '5px 5px' }}>{fleetOwner.name}</Button>
      </Link>
    </>
  )
}

export default FleetButton
