import classNames from 'classnames'
import styles from './button.module.css'

interface OwnProps {
  kind?: 'primary' | 'secondary'
  size?: 'normal' | 'small'
  loading?: boolean
}
type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & OwnProps

const Button = ({
  children,
  onClick,
  type = 'button',
  kind = 'primary',
  size = 'normal',
  loading = false,
  disabled = false,
  ...props
}: Props): JSX.Element => (
  <button
    type={type}
    className={classNames([styles.button, `button ${kind}`, `button ${size}`, { [styles.loading]: loading }])}
    onClick={onClick}
    disabled={disabled || loading}
    {...props}
  >
    {children}
  </button>
)

export default Button
